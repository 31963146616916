<template>
  <v-container fluid fill-height :class="$style.auth" class="d-flex justify-center align-center flex-column pa-0"
    style="width: 100%; min-width: 320px; max-width: 320px;">
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl" rounded :class="$style.form">
            <h3 class="text-center">RLT-008 アカウント切替確認画面</h3>
            <v-row>
              <v-col class="d-flex justify-center">
                <base-btn label="確認しました" @click="migrationConfirm('1')" :style="{
                  color: 'white',
                  fontSize: '1rem',
                  boxShadow: 'none'
                }" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <base-btn label="中断する" @click="migrationConfirm('0')" :style="{
                  'background-color': '#ffb95d',
                  color: 'white',
                  fontSize: '1rem',
                  boxShadow: 'none'
                }" />
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="6">
                <v-btn class="text-center" exact text block color="#5993ac" :class="$style.back"
                  :to="{ name: 'migration-start' }">
                  <v-icon :class="$style.back_icon">mdi-chevron-left</v-icon>戻る
                  (Back)
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import utility from "@/utility";

export default {
  data() {
    return {
      htmlContent: `
          <!DOCTYPE html>
          <html lang="ja">
          <head>
          <meta charset="utf-8">
          <title>RLT-008 アカウント切替確認画面</title>
          </head>
          <body>
            <h2>RLT-008 アカウント切替確認画面</h2>
            <form action="https://app.mlp-awv01.com/migration/confirm" method="GET">
              <input type="hidden" name="associateTid" value="SUonaVS3QcNDjr1y5sa4sijZ1M1wXQX3goEbF">
              <input type="hidden" name="associateFlag" value="1">
              <p><input type="submit" value="確認しました"></p>
            </form>
            <form action="https://app.mlp-awv01.com/migration/confirm" method="GET">
              <input type="hidden" name="associateTid" value="SUonaVS3QcNDjr1y5sa4sijZ1M1wXQX3goEbF">
              <input type="hidden" name="associateFlag" value="0">
              <p><input type="submit" value="中断する"></p>
            </form>
          </body>
          </html>
          `,
      resourceName: "migration",
      formKey: "account-index",
      title: "アカウント切替確認",
      titleEn: "Confirm Account Switch"
    };
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    migrationConfirm(associateFlag) {
      const associateTid = this.$route.params.associateTid;
      var data = {
        "associateTid": associateTid,
        "associateFlag": associateFlag
      }
      this.$store
        .dispatch(`${this.resourceName}/entity/migrationConfirm`, data)
        .then((response) => {
          this.$store.commit("form/clearDirty");
          // TODO: add more action

        })
        .catch(() => { });

    },
  },
  created() {
    // アカウント情報が存在しない場合は、アカウント情報画面に強制的に遷移
    if (!Object.keys(this.entity).length) {
      this.$router.push({ name: "migration-start" });
    }
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();

  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}

.textfield {
  label {
    font-size: 14px !important;
  }
}

.back {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.back_icon {
  margin-top: 2px;
}
</style>

