var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-center align-center flex-column pa-0",class:_vm.$style.auth,staticStyle:{"width":"100%","min-width":"320px","max-width":"320px"},attrs:{"fluid":"","fill-height":""}},[_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-1 pa-5 mb-3 rounded-xl",class:_vm.$style.form,attrs:{"color":"#fff","rounded":""}},[_c('h3',{staticClass:"text-center"},[_vm._v("RLT-008 アカウント切替確認画面")]),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('base-btn',{style:({
                color: 'white',
                fontSize: '1rem',
                boxShadow: 'none'
              }),attrs:{"label":"確認しました"},on:{"click":function($event){return _vm.migrationConfirm('1')}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('base-btn',{style:({
                'background-color': '#ffb95d',
                color: 'white',
                fontSize: '1rem',
                boxShadow: 'none'
              }),attrs:{"label":"中断する"},on:{"click":function($event){return _vm.migrationConfirm('0')}}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-center",class:_vm.$style.back,attrs:{"exact":"","text":"","block":"","color":"#5993ac","to":{ name: 'migration-start' }}},[_c('v-icon',{class:_vm.$style.back_icon},[_vm._v("mdi-chevron-left")]),_vm._v("戻る (Back) ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }